<script setup lang="ts">
const store = useBookingStore();
const { reviews } = storeToRefs(store);
useHead({
	script: [
		{
			key: 'jsonld-business-only',
			type: 'application/ld+json',
			children: businessOnly(reviews.value),
		},
	],
});

useSeoMeta({
	title: 'Diskubre Travel Palawan - Official Website',
	ogTitle: 'Diskubre Travel Official Website',
	description: 'Palawan most trusted travel agency for booking tours, transportation and activities. Book with us today!',
	ogDescription: 'Palawan most trusted travel agency for booking tours, transportation and activities. Book with us today!',
	ogImage: [{ url: 'https://www.diskubrepalawan.com/images/logos/logo.png' }],
	ogSiteName: 'Diskubre Travel Palawan',
	ogUrl: 'https://www.diskubrepalawan.com',
});
</script>

<template>
	<div>
		<MainBanner />
		<CurrateCards />
		<BestSeller />
	</div>
</template>
